<template>
  <div class="is-relative" :style="{width}">
    <p v-if="!isLittleScroll" class="mb-1" :class="[classTitle]">{{titleDropdown}}</p>
    <div
      class="input-filter-specific"
      :class="[classInput]"
      @click="() => handleClickInput('status')"
    >
      <div class="flex-center">
        <div v-if="showFilterIcon" class="flex-center filter-funnel-icon">
          <FilterFunnel />
        </div>
        <p :class="[{'averta-bold': !isReguler}]">
          {{ selectedStatus ? selectedStatus.label : placeholder }}
        </p>
      </div>
      <a class="card-header-icon p-0" v-if="!hideIconDropdown">
        <b-icon :icon="openStatus ? 'menu-up' : 'menu-down'"> </b-icon>
      </a>
    </div>
    <div
      class="dropdown-specific-wrapper status"
      :style="{ width: widthFilter ? widthFilter : '100%' }"
      v-if="openStatus"
      v-click-outside="closeDropdownStatus"
    >
      <p class="averta-bold label-16 px-4 pt-4 pb-2">Pilih Status</p>
      <div class="content-dropdown-wrapper status is-flex">
        <div class="status-parent-item-wrapper">
          <div
            v-for="(item, index) in dataStatus"
            :key="index"
            @click="() => handleSelectStatus(item)"
            class="status-parent-item"
            :class="{ active: item.index === selectedParentStatus }"
          >
            <p :class="{ 'averta-black': item.index === selectedParentStatus }">
              {{ item.name }}
            </p>
            <img :src="require('@/assets/img/arrow-right-grey.png')" alt="" />
          </div>
        </div>
        <div class="status-right-section">
          <p class="averta-bold px-4 label-16 mb-3" style="margin-top: 12px">
            {{ dataStatus[selectedParentStatus].name }}
          </p>
          <div class="status-child-item-wrapper">
            <div
              v-for="(item, index) in dataStatus[selectedParentStatus].submenu"
              :key="index"
              class="flex-shrink-0 status-child-item"
              @click="() => handleSelectItem(item, 'status')"
            >
              <div
                class="item-content-dropdown"
                :class="{
                  active:
                    selectedStatus &&
                    selectedStatus.status === item.status &&
                    selectedStatus.type === item.type,
                }"
              >
                <p>{{ item.label }}</p>
                <div
                  class="custom-radio-button"
                  :class="{
                    active:
                      selectedStatus &&
                      selectedStatus.status === item.status &&
                      selectedStatus.type === item.type,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DropdownStatus',
  props: {
    selectedStatusProps: {
      type: Object,
      default: null
    },
    width: {
      type: String,
      default: '100%'
    },
    widthFilter: {
      type: String,
      default: ''
    },
    titleDropdown: {
      type: String,
      default: 'Pilih Status'
    },
    placeholder: {
      type: String,
      default: 'Pilih Status'
    },
    classTitle: {
      type: String,
      default: ''
    },
    classInput: {
      type: String,
      default: ''
    },
    showFilterIcon: {
      type: Boolean,
      default: false
    },
    isReguler: {
      type: Boolean,
      default: false
    },
    isLittleScroll: {
      type: Boolean,
      default: false
    },
    hideIconDropdown: {
      type: Boolean,
      default: false
    },
    removedStatus: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FilterFunnel: () => import('@/components/icons/FilterFunnel.vue')
  },
  data () {
    return {
      dataStatus: [],
      openStatus: false,
      selectedStatus: null,
      selectedParentStatus: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    closeDropdownStatus () {
      this.openStatus = false
    },
    handleClickInput (type) {
      this.openStatus = !this.openStatus
      this.$emit('handleClickInput', type)
    },
    handleSelectItem (item, type) {
      this.selectedStatus = item
      this.$emit('handleSelectItem', { data: this.selectedStatus, type: 'status' })
    },
    handleSelectStatus (item) {
      this.selectedParentStatus = item.index
      if (this.dataStatus[item.index].submenu.length === 1) {
        this.selectedStatus = this.dataStatus[item.index].submenu[0]
        this.$emit('handleSelectItem', { data: this.selectedStatus, type: 'status' })
      }
    },
    getListStatus () {
      switch (this.user.roles[0].id) {
        // Business Support
        case 1:
          this.dataStatus = [
            {
              index: 0,
              name: 'Survey',
              submenu: [
                {
                  label: 'Survey Terlambat',
                  type: 'survey',
                  status: 'late'
                },
                {
                  label: 'Survey Hari Ini',
                  type: 'survey',
                  status: 'today'
                },
                {
                  label: 'Survey Besok',
                  type: 'survey',
                  status: 'tomorrow'
                },
                {
                  label: 'Survey Terjadwal',
                  type: 'survey',
                  status: 'scheduled'
                },
                {
                  label: 'Survey Selesai',
                  type: 'survey',
                  status: 'complete'
                },
                {
                  label: 'Survey Dibatalkan',
                  type: 'survey',
                  status: 'cancel'
                },
                {
                  label: 'Semua Survey',
                  type: 'survey',
                  status: 'all'
                }
              ]
            },
            {
              index: 1,
              name: 'Approval',
              submenu: [
                {
                  label: 'Proses Internal',
                  type: 'offering',
                  status: 'internal'
                },
                {
                  label: 'Menunggu Konfirmasi Client',
                  type: 'offering',
                  status: 'client'
                },
                {
                  label: 'Penawaran Approved',
                  type: 'offering',
                  status: 'approved'
                },
                {
                  label: 'Penawaran Rejected',
                  type: 'offering',
                  status: 'rejected'
                },
                {
                  label: 'Penawaran Expired',
                  type: 'offering',
                  status: 'expired'
                },
                {
                  label: 'Semua Penawaran',
                  type: 'offering',
                  status: 'all'
                }
              ]
            },
            {
              index: 2,
              name: 'Proyek',
              submenu: [
                {
                  label: 'Proyek In-Planning',
                  type: 'project-active',
                  status: 'in-planning'
                },
                {
                  label: 'Proyek Aktif',
                  type: 'project-active',
                  status: 'active'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-active',
                  status: 'all'
                }
              ]
            },
            {
              index: 3,
              name: 'Pembayaran',
              submenu: [
                {
                  label: 'Menunggu Pembayaran',
                  type: 'project-complete',
                  status: 'waiting'
                },
                {
                  label: 'Pembayaran Berhasil',
                  type: 'project-complete',
                  status: 'success'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-complete',
                  status: 'all'
                }
              ]
            }
          ]
          break

        // Konsultan Survey
        case 2:
          this.dataStatus = [
            {
              index: 0,
              name: 'Survey Berlangsung',
              submenu: [
                {
                  label: 'Survey Hari Ini',
                  type: 'survey-progress',
                  status: 'today'
                },
                {
                  label: 'Survey Terlambat',
                  type: 'survey-progress',
                  status: 'late'
                },
                {
                  label: 'Semua Survey',
                  type: 'survey-progress',
                  status: 'all'
                }
              ]
            },
            {
              index: 1,
              name: 'Survey Terjadwal',
              submenu: [
                {
                  label: 'Survey Terjadwal',
                  type: 'survey-scheduled',
                  status: 'survey-scheduled'
                }
              ]
            },
            {
              index: 2,
              name: 'Survey Selesai',
              submenu: [
                {
                  label: 'Survey Selesai',
                  type: 'survey-complete',
                  status: 'survey-complete'
                }
              ]
            },
            {
              index: 3,
              name: 'Survey Dibatalkan',
              submenu: [
                {
                  label: 'Survey Dibatalkan',
                  type: 'survey-cancel',
                  status: 'survey-cancel'
                }
              ]
            },
            {
              index: 4,
              name: 'Proses Internal',
              submenu: [
                {
                  label: 'Proses Site Manager',
                  type: 'internal',
                  status: 'sm'
                },
                {
                  label: 'Proses Purchasing',
                  type: 'internal',
                  status: 'purchasing'
                },
                {
                  label: 'Proses Business Support',
                  type: 'internal',
                  status: 'bs'
                },
                {
                  label: 'Semua Penawaran',
                  type: 'internal',
                  status: 'all'
                }
              ]
            },
            {
              index: 5,
              name: 'Approval',
              submenu: [
                {
                  label: 'Approval',
                  type: 'client',
                  status: 'client'
                }
              ]
            },
            {
              index: 6,
              name: 'Penawaran Disetujui',
              submenu: [
                {
                  label: 'Penawaran Disetujui',
                  type: 'approved',
                  status: 'approved'
                }
              ]
            },
            {
              index: 7,
              name: 'Penawaran Ditolak',
              submenu: [
                {
                  label: 'Penawaran Ditolak',
                  type: 'rejected',
                  status: 'rejected'
                }
              ]
            },
            {
              index: 8,
              name: 'Proyek',
              submenu: [
                {
                  label: 'Proyek In-Planning',
                  type: 'project-active',
                  status: 'in-planning'
                },
                {
                  label: 'Proyek Aktif',
                  type: 'project-active',
                  status: 'active'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-active',
                  status: 'all'
                }
              ]
            },
            {
              index: 9,
              name: 'Pembayaran',
              submenu: [
                {
                  label: 'Pembayaran',
                  type: 'project-complete',
                  status: 'project-complete'
                }
              ]
            }
          ]
          break

        // Purchasing
        case 4:
          this.dataStatus = [
            {
              index: 0,
              name: 'Proses Internal Purchasing',
              submenu: [
                {
                  label: 'Proses Purchasing',
                  type: 'offering',
                  status: 'internal'
                }
              ]
            },
            {
              index: 1,
              name: 'Approval',
              submenu: [
                {
                  label: 'Proses BS',
                  type: 'offering',
                  status: 'bs'
                },
                {
                  label: 'Menunggu Konfirmasi Client',
                  type: 'offering',
                  status: 'client'
                },
                {
                  label: 'Penawaran Rejected',
                  type: 'offering',
                  status: 'rejected'
                },
                {
                  label: 'Semua Penawaran',
                  type: 'offering',
                  status: 'all'
                }
              ]
            },
            {
              index: 2,
              name: 'Proyek',
              submenu: [
                {
                  label: 'Proyek Direncanakan',
                  type: 'project-active',
                  status: 'in-planning'
                },
                {
                  label: 'Proyek Aktif',
                  type: 'project-active',
                  status: 'active'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-active',
                  status: 'all'
                }
              ]
            },
            {
              index: 3,
              name: 'Pembayaran',
              submenu: [
                {
                  label: 'Pembayaran',
                  type: 'project-complete',
                  status: 'complete'
                }
              ]
            }
          ]
          break

        // Site Manager
        case 5:
          this.dataStatus = [
            {
              index: 0,
              name: 'Survey',
              submenu: [
                {
                  label: 'Survey Terlambat',
                  type: 'survey',
                  status: 'late'
                },
                {
                  label: 'Survey Hari Ini',
                  type: 'survey',
                  status: 'today'
                },
                {
                  label: 'Survey Besok',
                  type: 'survey',
                  status: 'tomorrow'
                },
                {
                  label: 'Survey Terjadwal',
                  type: 'survey',
                  status: 'scheduled'
                },
                {
                  label: 'Survey Selesai',
                  type: 'survey',
                  status: 'complete'
                },
                {
                  label: 'Survey Dibatalkan',
                  type: 'survey',
                  status: 'cancel'
                },
                {
                  label: 'Semua Survey',
                  type: 'survey',
                  status: 'all'
                }
              ]
            },
            {
              index: 1,
              name: 'Approval',
              submenu: [
                {
                  label: 'Proses Internal',
                  type: 'offering',
                  status: 'internal'
                },
                {
                  label: 'Menunggu Konfirmasi Client',
                  type: 'offering',
                  status: 'client'
                },
                {
                  label: 'Penawaran Approved',
                  type: 'offering',
                  status: 'approved'
                },
                {
                  label: 'Penawaran Rejected',
                  type: 'offering',
                  status: 'rejected'
                },
                {
                  label: 'Semua Penawaran',
                  type: 'offering',
                  status: 'all'
                }
              ]
            },
            {
              index: 2,
              name: 'Proyek',
              submenu: [
                {
                  label: 'Proyek In-Planning',
                  type: 'project-active',
                  status: 'in-planning'
                },
                {
                  label: 'Proyek Aktif',
                  type: 'project-active',
                  status: 'active'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-active',
                  status: 'all'
                }
              ]
            },
            {
              index: 3,
              name: 'Pembayaran',
              submenu: [
                {
                  label: 'Menunggu Pembayaran',
                  type: 'project-complete',
                  status: 'waiting'
                },
                {
                  label: 'Pembayaran Berhasil',
                  type: 'project-complete',
                  status: 'success'
                },
                {
                  label: 'Semua Proyek',
                  type: 'project-complete',
                  status: 'all'
                }
              ]
            }
          ]
          break

        default:
          this.dataStatus = []
          break
      }

      if (this.dataStatus.length > 0) {
        this.dataStatus = this.dataStatus.map(category => {
          category.submenu = category.submenu.filter(item =>
            !this.removedStatus.some(removeItem => removeItem.type === item.type && removeItem.status === item.status)
          )
          return category
        })
      }
      // if (this.statusSpecific && this.statusSpecific.length > 0) {
      //   this.dataStatus = this.statusSpecific
      // }
      // this.isLoadingStatus = true

      // const payload = {
      //   url: this.url,
      //   filter: true
      // }

      // this.$store
      //   .dispatch('projectV2/getTabsProject', payload)
      //   .then(response => {
      //     const res = response.data.data
      //     this.dataStatus = res
      //     this.isLoadingStatus = false
      //   })
      //   .catch(error => {
      //     this.isLoadingStatus = false
      //     alertErrorMessage(error)
      //   })
    }
  },
  created () {
    this.getListStatus()
  },
  watch: {
    selectedStatusProps: {
      handler () {
        this.selectedStatus = this.selectedStatusProps
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.filter-funnel-icon {
  margin-left: -5px;
  margin-right: 3px;
}

.input-filter-specific {
  border: 1px solid #e1e1e1;
  padding: 10px;
  border-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.dropdown-specific-wrapper {
  box-shadow: 6px 7px 50px 0px #00000014;
  position: absolute;
  top: 80px;
  right: 0px;
  background: white;
  z-index: 5;
  overflow: hidden;
}

.dropdown-specific-wrapper.status {
  width: 145%;
  border-radius: 10px;
}

.content-dropdown-wrapper {
  max-height: 160px;
  width: 100%;
  overflow: auto;
}

.content-dropdown-wrapper.status {
  max-height: 200px;
}

.item-content-dropdown {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.item-content-dropdown:hover,
.item-content-dropdown.active {
  background: #e100091a;
}

.custom-radio-button {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #868686;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  flex-shrink: 0;
}

.custom-radio-button.active::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background: #e10009;
}

.status-parent-item-wrapper {
  min-width: 13em;
  width: 33%;
  height: 12em;
  overflow: auto;
  border-right: 1px solid #e1e1e1;
  flex-shrink: 0;
}

.status-parent-item {
  padding: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-parent-item:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.status-parent-item:hover,
.status-parent-item.active {
  background: #e100091a;
}

.status-child-item-wrapper {
  /* width: 30em; */
  width: 100%;
  max-height: 152px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.status-child-item {
  /* width: 235px; */
  width: 49%;
}

.status-right-section {
  width: 67%;
}
</style>
